import { normalizeTel } from '@amo/core/utils/normalize.js';
import _ from 'lodash';
import moment from 'moment';
import {
    getAdresse1SouscripteurDemandeTarifInit,
    getAdresse2SouscripteurDemandeTarifInit,
    getAdresse3SouscripteurDemandeTarifInit,
    getAnnulationPermisAntecedentsDemandeTarifInit,
    getAntecedentsDemandeTarifInit,
    getApporteur1Init,
    getApporteur2Init,
    getAssure3DerniersMoisVehiculeDemandeTarifInit,
    getBirthCityPersonnePrincipaleDemandeTarifInit,
    getBirthDepartmentPersonnePrincipaleDemandeTarifInit,
    getBirthCityTuteurDemandeTarifInit,
    getBirthCountryPersonnePrincipaleDemandeTarifInit,
    getBirthCountryTuteurDemandeTarifInit,
    getBirthNamePersonnePrincipaleDemandeTarifInit,
    getBirthNameTuteurDemandeTarifInit,
    getCPSouscripteurDemandeTarifInit,
    getCanalDemandeTarifInit,
    getCarteGriseFrancaiseVehiculeDemandeTarifInit,
    getCivilitePersonneMoraleTarif,
    getCivilitePersonnePrincipaleDemandeTarifInit,
    getCiviliteTuteurDemandeTarifInit,
    getCodePostalGarageVehiculeDemandeTarifInit,
    getCodePromoDemandeTarifInit,
    getCondamnationAlcoolemieAntecedentsDemandeTarifInit,
    getCondamnationConduiteSansAssuranceAntecedentsDemandeTarifInit,
    getCondamnationDelitFuiteAntecedentsDemandeTarifInit,
    getCondamnationStupefiantsAntecedentsDemandeTarifInit,
    getConducteurMineurPersonnePrincipaleDemandeTarifInit,
    getConnuCommentInit,
    getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getDateMECVehiculeDemandeTarif,
    getDateNaissancePersonnePrincipaleDemandeTarifInit,
    getDateNaissanceTuteurDemandeTarifInit,
    getDevisHamonAssureurActuelIdDemandeContratInit,
    getDevisHamonAssureurActuelIdDemandeTarifInit,
    getDevisHamonAssureurActuelNameDemandeContratInit,
    getDevisHamonAssureurActuelNameDemandeTarifInit,
    getDevisHamonContratActuelDemandeContratInit,
    getDevisHamonContratActuelDemandeTarifInit,
    getDevisHamonDemandeContratInit,
    getDevisHamonDemandeTarifInit,
    getDevoirASS2DevoirConseilDemandeTarifInit,
    getDevoirASS3DevoirConseilDemandeTarifInit,
    getDevoirAccEqpDevoirConseilDemandeTarifInit,
    getDevoirDommageDevoirConseilDemandeTarifInit,
    getDevoirICDevoirConseilDemandeTarifInit,
    getDevoirMontantAccEqpDevoirConseilDemandeTarifInit,
    getDevoirRachatDevoirConseilDemandeTarifInit,
    getDevoirVolDevoirConseilDemandeTarifInit,
    getEmailSouscripteurTarif,
    getFormuleChoisiePoliceDemandeTarifInit,
    getFractionnementPoliceDemandeTarifInit,
    getGPDemandeTarifInit,
    getIdSocietyPersonneMoraleTarif,
    getIdentifiantSRAVehiculeDemandeTarifInit,
    getImmatriculationVehiculeDemandeTarifInit,
    getLegalStatusPersonneMoraleTarif,
    getListePermisPersonnePrincipaleDemandeTarifInit,
    getListeSinistresAntecedentsDemandeTarifInit,
    getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit,
    getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit,
    getNomPersonneMoraleTarif,
    getNomPersonnePrincipaleDemandeTarifInit,
    getNomTitulaireDemandeContratInit,
    getNomTuteurDemandeTarifInit,
    getNumPermisSouscripteurTarif,
    getOptinNewsletterDemandeDevisInit,
    getOptinUtilisationDonneesDemandeDevisInit,
    getOptionsDemandeTarifInit,
    getOptionsFranchiseDemandeTarifInit,
    getOptionsListeDemandeTarifInit,
    getPermisA1PersonnePrincipaleDemandeTarifInit,
    getPermisA2PersonnePrincipaleDemandeTarifInit,
    getPermisAMPersonnePrincipaleDemandeTarifInit,
    getPermisAPersonnePrincipaleDemandeTarifInit,
    getPermisBPersonnePrincipaleDemandeTarifInit,
    getPersonneMoraleDemandeTarifInit,
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit,
    getPrenomPersonnePrincipaleDemandeTarifInit,
    getPrenomTuteurDemandeTarifInit,
    getSignatureEmailDemandeContratInit,
    getSignaturePhoneDemandeContratInit,
    getState,
    getSuspensionPermisAntecedentsDemandeTarifInit,
    getTelPortableSouscripteurTarif,
    getTiersPayeurBirthCityDemandeContratInit,
    getTiersPayeurBirthCountryDemandeContratInit,
    getTiersPayeurBirthDateDemandeContratInit,
    getTiersPayeurBirthNameDemandeContratInit,
    getTiersPayeurDemandeContratInit,
    getTiersPayeurFirstNameDemandeContratInit,
    getTiersPayeurLastNameDemandeContratInit,
    getTiersPayeurRelationshipDemandeContratInit,
    getTiersPayeurThirdPayerReasonDemandeContratInit,
    getTiersPayeurTypeDemandeContratInit,
    getTuteurDemandeTarifInit,
    getUsageNonLivraisonVehiculeDemandeTarifInit,
    getUsageVehiculeDemandeTarif,
    getValeurCrmAutoPersonnePrincipaleDemandeTarifInit,
    getValeurCrmMotoPersonnePrincipaleDemandeTarifInit,
    getValeurVehiculeVehiculeDemandeTarifInit,
    getVehiculeImmatriculeVehiculeDemandeTarifInit,
    getVilleGarageVehiculeDemandeTarifInit,
    getVilleSouscripteurDemandeTarifInit,
    getPossessionPlusDe3MoisVehiculeDemandeTarifInit,
    getDateHeureEffetDemandeContratInit, getDateAchatVehiculeDemandeTarif, getParrainageDemandeTarifInit
} from '../../../redux/selectors/init/init.selectors';
import {
    getCylindreeVehicule,
    getMarqueVehicule,
    getSraVehicule,
    getTypeVehicule,
} from '../../../redux/selectors/vehicule/vehicule.selectors';

export const InitialValues = (state) => {
    const isDevisOrDevisOrange = getState(state) === 'devis' || getState(state) === 'devis_orange';

    return {
        redirection: false,
        searchBy: getTypeVehicule(state) ? 'modele' : null,
        typeVehicule: getTypeVehicule(state),
        marqueVehicule: getMarqueVehicule(state),
        modeleVehicule: getSraVehicule(state),
        cylindreeVehicule: getCylindreeVehicule(state) && String(getCylindreeVehicule(state)),
        options: getOptionsDemandeTarifInit(state),
        optionsFranchise: getOptionsFranchiseDemandeTarifInit(state),
        optionsListe: getOptionsListeDemandeTarifInit(state),
        /* Vérifier avec les selectors getAssureMotoInit ? */
        assure_moto: isDevisOrDevisOrange
            ? getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state)
                ? '1'
                : '0'
            : undefined,
        assure_auto: isDevisOrDevisOrange
            ? getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state)
                ? '1'
                : '0'
            : undefined,
        /* Déduction de la valeur entre societe ou a son nom, impossible entre nom de l'enfant ou a votre nom */
        titulaireCarteGrise:
            getPersonneMoraleDemandeTarifInit(state) && getCivilitePersonneMoraleTarif(state)
                ? '2'
                : getNomPersonnePrincipaleDemandeTarifInit(state) || getPrenomPersonnePrincipaleDemandeTarifInit(state)
                ? '1'
                : undefined,
        sinistre_auto: isDevisOrDevisOrange
            ? getListeSinistresAntecedentsDemandeTarifInit(state) &&
              getListeSinistresAntecedentsDemandeTarifInit(state).length > 0
                ? '1'
                : '0'
            : undefined,
        DemandeDevis: {
            ConnuComment: getConnuCommentInit(state),
            OptinNewsletter: getOptinNewsletterDemandeDevisInit(state),
            OptinUtilisationDonnees: getOptinUtilisationDonneesDemandeDevisInit(state),
        },
        DemandeTarif: {
            Parrainage: getParrainageDemandeTarifInit(state),
            GoodbyePack: getGPDemandeTarifInit(state),
            DevisHamon: getDevisHamonDemandeTarifInit(state),
            DevisHamonAssureurActuelName: getDevisHamonAssureurActuelNameDemandeTarifInit(state),
            DevisHamonAssureurActuelId: getDevisHamonAssureurActuelIdDemandeTarifInit(state),
            DevisHamonContratActuel: getDevisHamonContratActuelDemandeTarifInit(state),
            CodePromo: {
                CodePromo: getCodePromoDemandeTarifInit(state),
            },
            Police: {
                Canal: getCanalDemandeTarifInit(state),
                FractionnementChoisi: getFractionnementPoliceDemandeTarifInit(state) || 'M',
                FormuleChoisie: getFormuleChoisiePoliceDemandeTarifInit(state),
                IdApporteur1: getApporteur1Init(state)?.codeBelair,
                IdApporteur2: getApporteur2Init(state)?.codeBelair,
            },
            ListePersonnes: [
                {
                    TypePersonne: 'P',
                    RoleConducteur: 'P',
                    Souscripteur:
                        getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                        moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY').add(
                            18,
                            'years',
                        ) > moment()
                            ? false
                            : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state) !== 'false',
                    DateNaissance:
                        getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                        moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY'),
                    ListePermis:
                        getListePermisPersonnePrincipaleDemandeTarifInit(state).length > 0
                            ? [
                                  {
                                      TypePermis: getPermisAMPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                      DatePermis:
                                          getPermisAMPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                          moment(
                                              getPermisAMPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                              'DD/MM/YYYY',
                                          ),
                                  },
                                  {
                                      TypePermis: getPermisA1PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                      DatePermis:
                                          getPermisA1PersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                          moment(
                                              getPermisA1PersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                              'DD/MM/YYYY',
                                          ),
                                  },
                                  {
                                      TypePermis: getPermisA2PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                      DatePermis:
                                          getPermisA2PersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                          moment(
                                              getPermisA2PersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                              'DD/MM/YYYY',
                                          ),
                                  },
                                  {
                                      TypePermis: getPermisAPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                      DatePermis:
                                          getPermisAPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                          moment(
                                              getPermisAPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                              'DD/MM/YYYY',
                                          ),
                                  },
                                  {
                                      TypePermis: getPermisBPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                      DatePermis:
                                          getPermisBPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                          moment(
                                              getPermisBPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                              'DD/MM/YYYY',
                                          ),
                                  },
                              ]
                            : null,
                    Civilite: getCivilitePersonnePrincipaleDemandeTarifInit(state),
                    Nom: getNomPersonnePrincipaleDemandeTarifInit(state),
                    DejaClient: '0', //getIsDejaClientDemandeTarifInit(state),
                    Prenom: getPrenomPersonnePrincipaleDemandeTarifInit(state),
                    Adresse1: getAdresse1SouscripteurDemandeTarifInit(state),
                    Adresse2: getAdresse2SouscripteurDemandeTarifInit(state),
                    Adresse3: getAdresse3SouscripteurDemandeTarifInit(state),
                    Ville: getVilleSouscripteurDemandeTarifInit(state),
                    CP: getCPSouscripteurDemandeTarifInit(state),
                    TelPortable: normalizeTel(getTelPortableSouscripteurTarif(state)),
                    NumPermis: getNumPermisSouscripteurTarif(state),
                    Email: getEmailSouscripteurTarif(state),
                    ValeurCrmMoto: getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state)
                        ? String(Number(getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                        : null,
                    CrmMoto50Plus3Ans: getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                    ValeurCrmAuto: getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state)
                        ? String(Number(getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                        : null,
                    CrmAuto50Plus3Ans: getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                    NbMoisAssuranceMoto: getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state)
                        ? Number(getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                        : null,
                    NbMoisAssuranceAuto: getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state)
                        ? Number(getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                        : null,
                    BirthCountry: getBirthCountryPersonnePrincipaleDemandeTarifInit(state),
                    BirthCity: getBirthCityPersonnePrincipaleDemandeTarifInit(state),
                    BirthDepartment: getBirthDepartmentPersonnePrincipaleDemandeTarifInit(state),
                    BirthName: getBirthNamePersonnePrincipaleDemandeTarifInit(state),
                    ConducteurMineur: getConducteurMineurPersonnePrincipaleDemandeTarifInit(state) ?? false,
                    NbMoisReferenceMoto: '24',
                    NbMoisReferenceAuto: '24',
                },
                !_.isEmpty(getTuteurDemandeTarifInit(state))
                    ? {
                          Souscripteur: true,
                          TypePersonne: 'P',
                          RoleConducteur: 'N',
                          DateNaissance:
                              getDateNaissanceTuteurDemandeTarifInit(state) &&
                              moment(getDateNaissanceTuteurDemandeTarifInit(state), 'DD/MM/YYYY'),
                          Civilite: getCiviliteTuteurDemandeTarifInit(state),
                          Nom: getNomTuteurDemandeTarifInit(state),
                          Prenom: getPrenomTuteurDemandeTarifInit(state),
                          BirthCountry: getBirthCountryTuteurDemandeTarifInit(state),
                          BirthCity: getBirthCityTuteurDemandeTarifInit(state),
                          BirthName: getBirthNameTuteurDemandeTarifInit(state),
                      }
                    : !_.isEmpty(getPersonneMoraleDemandeTarifInit(state))
                    ? {
                          Souscripteur: true,
                          TypePersonne: 'M',
                          RoleConducteur: 'N',
                          Civilite: getCivilitePersonneMoraleTarif(state),
                          Nom: getNomPersonneMoraleTarif(state),
                          LegalStatus: getLegalStatusPersonneMoraleTarif(state),
                          IdSociety: getIdSocietyPersonneMoraleTarif(state),
                      }
                    : _.isEmpty(getTuteurDemandeTarifInit) &&
                      getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                      moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY').add(18, 'years') >
                          moment()
                    ? {
                          Souscripteur: true,
                          TypePersonne: 'P',
                          RoleConducteur: 'N',
                          BirthCountry: getBirthCountryTuteurDemandeTarifInit(state),
                          BirthCity: getBirthCityTuteurDemandeTarifInit(state),
                          BirthName: getBirthNameTuteurDemandeTarifInit(state),
                      }
                    : undefined,
            ],
            Vehicule: {
                /* Force à non en cas de reprise pour poser la question (champs non retournés dans le devis-ws) */
                possessionPlusDe3Mois: _.includes(['true', '1', true], getPossessionPlusDe3MoisVehiculeDemandeTarifInit(state)) ? '1' : undefined,
                VehiculeImmatricule: getImmatriculationVehiculeDemandeTarifInit(state)
                    ? '1'
                    : getVehiculeImmatriculeVehiculeDemandeTarifInit(state),
                SituationVehicule: '1',
                FamilleProduit: 'Moto',
                IdentifiantSRA: getIdentifiantSRAVehiculeDemandeTarifInit(state),
                DateAchatVehicule: getDateAchatVehiculeDemandeTarif(state) && moment(getDateAchatVehiculeDemandeTarif(state), 'DD/MM/YYYY'),
                DateMEC:
                    getDateMECVehiculeDemandeTarif(state) &&
                    moment(getDateMECVehiculeDemandeTarif(state), 'DD/MM/YYYY'),
                Assure3DerniersMois: _.includes(
                    ['true', '1', true],
                    getAssure3DerniersMoisVehiculeDemandeTarifInit(state),
                )
                    ? '1'
                    : _.includes(['false', '0', false], getAssure3DerniersMoisVehiculeDemandeTarifInit(state))
                    ? '0'
                    : undefined,
                UsageVehicule: getUsageVehiculeDemandeTarif(state),
                UsageNonLivraison: getUsageNonLivraisonVehiculeDemandeTarifInit(state),
                ValeurVehicule: getValeurVehiculeVehiculeDemandeTarifInit(state),
                CarteGriseFrancaise: getCarteGriseFrancaiseVehiculeDemandeTarifInit(state) && '1',
                VilleGarage: getVilleGarageVehiculeDemandeTarifInit(state),
                CodePostalGarage: getCodePostalGarageVehiculeDemandeTarifInit(state),
                Immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
                TitulaireCarteGrise: true,
            },
            Antecedents: {
                SuspensionPermis:
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === 'false' ||
                    getSuspensionPermisAntecedentsDemandeTarifInit(state) === '0'
                        ? '0'
                        : getSuspensionPermisAntecedentsDemandeTarifInit(state) === 'true' ||
                          getSuspensionPermisAntecedentsDemandeTarifInit(state) === '1'
                        ? '1'
                        : '',
                DateSuspensionPermis: getAntecedentsDemandeTarifInit(state).DateSuspensionPermis,
                NbMoisSuspensionPermis: getAntecedentsDemandeTarifInit(state).NbMoisSuspensionPermis,
                AnnulationPermis:
                    getAnnulationPermisAntecedentsDemandeTarifInit(state) === 'false' ||
                    getAnnulationPermisAntecedentsDemandeTarifInit(state) === '0'
                        ? '0'
                        : getAnnulationPermisAntecedentsDemandeTarifInit(state) === 'true' ||
                          getAnnulationPermisAntecedentsDemandeTarifInit(state) === '1'
                        ? '1'
                        : '',
                DateAnnulationPermis: getAntecedentsDemandeTarifInit(state).DateAnnulationPermis,
                CondamnationAlcoolemie: getCondamnationAlcoolemieAntecedentsDemandeTarifInit(state),
                TxAlcool: getAntecedentsDemandeTarifInit(state).TxAlcool,
                CondamnationStupefiants: getCondamnationStupefiantsAntecedentsDemandeTarifInit(state),
                CondamnationDelitFuite: getCondamnationDelitFuiteAntecedentsDemandeTarifInit(state),
                CondamnationConduiteSansAssurance:
                    getCondamnationConduiteSansAssuranceAntecedentsDemandeTarifInit(state),
                ResiliationAssureur: isDevisOrDevisOrange
                    ? _.includes(['false', '0', false], getAntecedentsDemandeTarifInit(state).ResiliationAssureur)
                        ? '0'
                        : '1'
                    : undefined,
                MotifResiliationAssureur: getAntecedentsDemandeTarifInit(state).MotifResiliationAssureur,
                DateResiliationAssureur: getAntecedentsDemandeTarifInit(state).DateResiliationAssureur,
                ListeSinistres: getListeSinistresAntecedentsDemandeTarifInit(state),
            },
            DevoirConseil: {
                DevoirVol:
                    getDevoirVolDevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirVolDevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirVolDevoirConseilDemandeTarifInit(state) === 'true' ||
                          getDevoirVolDevoirConseilDemandeTarifInit(state) === '1'
                        ? '1'
                        : '',
                DevoirDommage:
                    getDevoirDommageDevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirDommageDevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirDommageDevoirConseilDemandeTarifInit(state) === 'true' ||
                          getDevoirDommageDevoirConseilDemandeTarifInit(state) === '1'
                        ? '1'
                        : '',
                DevoirASS2:
                    getDevoirASS2DevoirConseilDemandeTarifInit(state) === 'false' ||
                    getDevoirASS2DevoirConseilDemandeTarifInit(state) === '0'
                        ? '0'
                        : getDevoirASS2DevoirConseilDemandeTarifInit(state) === 'true' ||
                          getDevoirASS2DevoirConseilDemandeTarifInit(state) === '1'
                        ? '1'
                        : '',
                DevoirASS3: getDevoirASS3DevoirConseilDemandeTarifInit(state),
                DevoirIC: getDevoirICDevoirConseilDemandeTarifInit(state),
                DevoirACCESSEQP: getDevoirAccEqpDevoirConseilDemandeTarifInit(state),
                DevoirMontantACCESSEQP: getDevoirMontantAccEqpDevoirConseilDemandeTarifInit(state),
                DevoirRAFRA: getDevoirRachatDevoirConseilDemandeTarifInit(state),
            },
        },
        DemandeContrat: {
            DevisHamon: getDevisHamonDemandeContratInit(state) ? '1' : '0',
            DevisHamonAssureurActuel:
                getDevisHamonAssureurActuelNameDemandeContratInit(state) &&
                getDevisHamonAssureurActuelIdDemandeContratInit(state)
                    ? `${getDevisHamonAssureurActuelIdDemandeContratInit(
                          state,
                      )}|${getDevisHamonAssureurActuelNameDemandeContratInit(state)}`
                    : null,
            DevisHamonAssureurActuelName: getDevisHamonAssureurActuelNameDemandeContratInit(state),
            DevisHamonAssureurActuelId: getDevisHamonAssureurActuelIdDemandeContratInit(state),
            DevisHamonContratActuel: getDevisHamonContratActuelDemandeContratInit(state),
            ModePaiementTerme: 'P',
            Signature: true,
            SignatureEmail: getSignatureEmailDemandeContratInit(state) || getEmailSouscripteurTarif(state),
            SignaturePhone: getSignaturePhoneDemandeContratInit(state) || getTelPortableSouscripteurTarif(state),
            NomTitulaire:
                getNomTitulaireDemandeContratInit(state) != 'undefined undefined'
                    ? getNomTitulaireDemandeContratInit(state)
                    : `${getNomPersonnePrincipaleDemandeTarifInit(state)} ${getPrenomPersonnePrincipaleDemandeTarifInit(
                          state,
                      )}`,
            DateHeureEffet: getDevisHamonDemandeContratInit(state) ? getDateHeureEffetDemandeContratInit(state) : moment().add(30, 'minutes').format('DD/MM/YYYY HH:mm'),
            TiersPayeur: !_.isEmpty(getTiersPayeurDemandeContratInit(state))
                ? {
                      Type: getTiersPayeurTypeDemandeContratInit(state) || 'P',
                      LastName: getTiersPayeurLastNameDemandeContratInit(state),
                      BirthName: getTiersPayeurBirthNameDemandeContratInit(state),
                      FirstName: getTiersPayeurFirstNameDemandeContratInit(state),
                      BirthDate: getTiersPayeurBirthDateDemandeContratInit(state),
                      BirthCountry: getTiersPayeurBirthCountryDemandeContratInit(state),
                      BirthCity: getTiersPayeurBirthCityDemandeContratInit(state),
                      Relationship: getTiersPayeurRelationshipDemandeContratInit(state),
                      ThirdPayerReason: getTiersPayeurThirdPayerReasonDemandeContratInit(state),
                  }
                : {},
        },
    };
};
