import { LargeSpinner } from '@amo/core/components/loaders';
import { hameconnage } from '@amo/core/utils/functions';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {getStepBySlug, getTitleByStep} from '../../../utils/function';
import BookingContainer from './Booking/BookingContainer';
import FooterContainer from './Footer/FooterContainer';
import HeaderContainer from './Header/HeaderContainer';
import RouteStep from './RouteStep';
import HeaderLanding from './Step/Vehicule/HeaderLanding/HeaderLanding';
import {formatDataForAnalytics} from "@amo/core/utils/formatDataForAnalytics.js";

const Init = (props) => {
    const {
        step,
        indexQuestion,
        stepInit,
        questionInit,
        goTo,
        demandeRappelLoading,
        nextQuestion,
        data,
        changeValue,
        setIndexQuestion,
        utm_source,
        errorsForm,
        analyticsSetEvent,
        codePromo,
        numeroContrat,
        personne0Init
    } = props;
    const [questions, setQuestions] = useState(document.getElementsByClassName('question'));
    const [showBooking, setShowBooking] = useState(false);

    useEffect(() => {
        hameconnage({ changeValue, setIndexQuestion });
        if(personne0Init?.DejaClient) {
            _.forEach(personne0Init, (value, key) => {
                changeValue(`DemandeTarif.ListePersonnes.0.${key}`, value)
            });
        }
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            analyticsSetEvent({
                event: 'funnelStepLoad',
                datas: {
                    'funnelStepLoad': {
                        'stepName': step < getStepBySlug('votre-tarif') ? document.getElementsByClassName('fade-in')[0]?.getElementsByClassName('question-title')[0]?.getAttribute('data-title') : getTitleByStep(step),
                        'parentStepName': step < getStepBySlug('votre-tarif') ? getTitleByStep(step) : '', //String : nom de la famille de step parente
                        'currentInfo': formatDataForAnalytics('moto', {...data, codePromo: codePromo, numeroContrat: numeroContrat}, step, indexQuestion),
                    }
                }
            });
        }
    }, [step, indexQuestion]);

    useEffect(() => {
        let listQuestion = [];

        /* retire la class fade-in à toutes les questions qui l'ont */
        _.map(document.getElementsByClassName('question'), (q) => {
            if (q.classList.contains('fade-in')) {
                q.classList.remove('fade-in');
            }
            listQuestion.push(q);
        });

        setQuestions(listQuestion);
        /* Ajoute la class fade-in à la question en cours */
        if (document.getElementsByClassName('question')[indexQuestion]) {
            document.getElementsByClassName('question')[indexQuestion].classList.add('fade-in');
        }
    }, [indexQuestion, step, data, demandeRappelLoading]);

    /* Fonction pour passer à la question suivante si btn n'est pas disabled et seulement avant l'étape tarif */
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && step < 5) {
            const question = document.getElementsByClassName('question')[indexQuestion];
            const btn = question.getElementsByClassName('btn btn-primary')[0];
            const isDisabled = btn.classList.contains('disabled');
            if (!isDisabled) {
                nextQuestion();
            }
        }
    };

    /* useEffect qui ajoute et retire l'event listener */
    useEffect(() => {
        if (step < 6) {
            document.querySelector('body').addEventListener('keydown', handleKeyDown);
            return () => {
                document.querySelector('body').removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [indexQuestion]);

    useEffect(() => {
        /* Redirige sur la dernière question où en était l'utilisateur, information prise depuis le localstorage */
        // attention au retour du paiement voir le comportement
        if (stepInit !== undefined && questionInit !== undefined) {
            goTo({ step: stepInit, question: questionInit });
        }
    }, [stepInit, questionInit]);

    return (
        <>
            {step === 0 && indexQuestion === 0 && utm_source !== null && <HeaderLanding {...props} />}
            <HeaderContainer nbQuestions={questions.length} {...props} setShowBooking={setShowBooking} analyticsSetEvent={analyticsSetEvent}/>
            {showBooking && <BookingContainer {...props} setShowBooking={setShowBooking} />}
            {demandeRappelLoading ? (
                <LargeSpinner message={'Nous enregistrons votre demande de rappel'} />
            ) : (
                <div className={step === getStepBySlug('votre-tarif') ? 'container-fluid' : 'container'}>
                    <div className={'row'}>
                        <div className={'text-center col-lg-12'}>
                            {data !== undefined &&
                                RouteStep.map((route, index) => {
                                    return (
                                        <div key={index}>
                                            {step === route.step &&
                                                route.main({
                                                    props,
                                                    other: { questions, setQuestions, id: route.slug },
                                                })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            )}
            <FooterContainer step={step} />
        </>
    );
};

export default Init;
